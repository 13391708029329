<template>
<div id="notification-drop-down" @focusout="hideNotifications" tabindex="0">
    <b-iconstack 
        @click="toggleNotifications"
        v-b-hover="handleIconHover"
        class="mr-3 cursor-pointer">
        <b-icon icon="circle-fill" :style="isIconHovered||showNotifications?'color:#DDE4F5':'color:transparent'" scale="2.3"></b-icon>
        <b-icon icon="bell" :style="isIconHovered||showNotifications?'color:var(--blue)':'color:#38455d'" scale="1.1"></b-icon>
        <b-icon icon="circle-fill" v-if="hasUnreadNotifs" shift-h="4" shift-v="4" style="color:#fd4739" scale="0.5"></b-icon>
    </b-iconstack>
    <b-card v-if="showNotifications" class="notification-card">
        <div class="notification-card-head">
            <div style="color:#010C14;font-weight:500;">Notifications</div>
            <div @click="markAllRead" class="avo-text-light cursor-pointer">Mark all as read</div>
        </div>
        <div class="notification-row"
                    v-for="notification in notifications"
                    :key="notification.notification_id"
                    v-on:mouseover="handleNotifHover(notification.notification_id)"
                    v-on:mouseleave="handleNotifHover(null)"
                    :style="isNotifHovered==notification.notification_id?'background-color:#F2F6FC':'background-color:white'">

            <div :class="'notification-info d-flex justify-content-center' + (notificationIsClickable(notification) ? ' cursor-pointer' : '')"
                @click="handleNotificationClick(notification)">
                <b-avatar class="mr-2"><span v-if="notification.source_initials">{{notification.source_initials}}</span></b-avatar>
                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div style="font-size:14px;font-weight:500;color:var(--dark);">{{notification.description}}</div>
                    <div class="avo-text-light-small">{{notification.occurred_at?formatDatetime(notification.occurred_at):"N/A"}}</div>
                </div>
            </div>
            <div class="d-flex">
                <b-iconstack
                        v-on:mouseover="handleReadHover(notification.notification_id)"
                        v-on:mouseleave="handleReadHover(null)"
                        @click="toggleReadStatus(notification.notification_id, notification.opened)"
                        class="mr-2 cursor-pointer"
                        v-b-popover.hover.bottom="notification.opened ? 'Mark as unread' : 'Mark as read'">
                    <b-icon icon="circle-fill" :style="readHover==notification.notification_id?'color:#BBC9EC':'color:#DDE4F5'" scale="1.25"></b-icon>
                    <b-icon v-if="!notification.opened" icon="circle-fill" style="color:var(--blue)" scale="0.75"></b-icon>
                </b-iconstack>
                <b-iconstack
                        v-on:mouseover="handleClearHover(notification.notification_id)"
                        v-on:mouseleave="handleClearHover(null)"
                        @click="clearNotification(notification.notification_id)"
                        class="mr-2 cursor-pointer"
                        v-b-popover.hover.bottom="'Clear notification'">
                    <b-icon icon="circle-fill" :style="clearHover==notification.notification_id?'color:#DDE4F5':'color:transparent'" scale="1.25"></b-icon>
                    <b-icon icon="x" :style="clearHover==notification.notification_id?'color:var(--dark)':'color:var(--gray-800)'" scale="1.35"></b-icon>
                </b-iconstack>
            </div>
        </div>
        <div v-if="notifications.length == 0">
            <div class="no-notifications-row">
                You have no notifications at this time.
            </div>
        </div>
        <div class="notification-card-foot cursor-pointer">
        </div>
    </b-card>
</div>
</template>

<script>
import { make_url, authenticated_request } from '../common.js'

export default {
  name: 'NotificationDropDown',
  components: {},
  data() {
      return {
          notifications: [],
          hasUnreadNotifs: false,
          showNotifications: false,
          isIconHovered: false,
          isNotifHovered: -1,
          clearHover: -1,
          readHover: -1,
          currentPage: 1,
          perPage: 5,
      }
  },
  methods: {
      getNotifications(){
        var url = make_url("/notifications/users/" + this.user_id).toString();
        if(this.user_worker_id){
            url = make_url("/notifications/workers/" + this.user_worker_id).toString();
        } 
        var vm = this;
        authenticated_request({
            method: "post",
            url: url,
            data: {
                page: vm.currentPage,
                per_page: vm.perPage,
            }
        }).then(function(response){
            vm.notifications = response.data.notifications;
        }).catch(function(error){
            console.log(error.response)
        })
      },
      formatDatetime(date) {
        const dateObj = new Date(date);
        return dateObj.toLocaleString();
      },
      getUnreadIndicator(){
        var url = make_url("/notifications/users/" + this.user_id + "/indicator");
        if(this.user_worker_id){
            url = make_url("/notifications/workers/" + this.user_worker_id + "/indicator");
        } 
        var vm = this;
        authenticated_request({
            method: "post",
            url: url,
            data: {
                page: vm.currentPage,
                per_page: vm.perPage
            }
        }).then(function(response){
            vm.hasUnreadNotifs = response.data.has_unopened;
        }).catch(function(error){
            console.log(error.response)
        })

      },
      notificationIsClickable(notification) {
        if(notification?.payload?.claim_id) {
            return true;
        }
        return false;
      },
      handleNotificationClick(notification) {
          if (!this.notificationIsClickable(notification)) {
              return
          }

          if(notification?.payload?.claim_id) {
                this.$router.push({
                    name: 'claim-view',
                    params: {
                        initialClaimId: notification.payload.claim_id
                    }
                })
          }
      },
      toggleReadStatus(notification_id, currently_opened){
          if (currently_opened){
              this.markUnread(notification_id);
              
          } else {
              this.markRead(notification_id);
          }
      },
      markRead(notification_id){
        var vm = this;
        authenticated_request({
            method: "put",
            url: make_url("/notifications/mark-read"),
            data: {
                notification_ids: [notification_id]
            }
        }).then(function(){
            var unopenedCount = 0;
            vm.notifications.forEach(function (item, index) {
                if(item.notification_id === notification_id){
                    vm.notifications[index]['opened'] = true;
                }
                if(!item.opened){
                    unopenedCount += 1;
                }
            });
            if(unopenedCount == 0){
                vm.hasUnreadNotifs = false;
            }
        }).catch(function(error){
            console.log(error.response)
        })
      },
      markUnread(notification_id){
        var vm = this;
        authenticated_request({
            method: "put",
            url: make_url("/notifications/mark-unread"),
            data: {
                notification_ids: [notification_id]
            }
        }).then(function(){
            vm.notifications.forEach(function (item, index) {
                if(item.notification_id === notification_id){
                    vm.notifications[index]['opened'] = false;
                }
            });
            vm.hasUnreadNotifs = true;
        }).catch(function(error){
            console.log(error.response)
        })
      },
      clearNotification(notification_id){
        var vm = this;
        authenticated_request({
            method: "delete",
            url: make_url("/notifications/clear"),
            data: {
                notification_id: notification_id
            }
        }).then(function(){
            vm.getNotifications();
        }).catch(function(error){
            console.log(error.response)
        })
      },
      markAllRead(){
        var notification_ids = [];
        this.notifications.forEach(function (item) {
            notification_ids.push(item.notification_id);
        });
        var vm = this;
        authenticated_request({
            method: "put",
            url: make_url("/notifications/mark-read"),
            data: {
                notification_ids: notification_ids
            }
        }).then(function(){
            vm.getNotifications();
            vm.hasUnreadNotifs = false;
        }).catch(function(error){
            console.log(error.response)
        })
      },
      toggleNotifications() {
          if (!this.showNotifications){
              this.getNotifications();
          }
          this.showNotifications = !this.showNotifications;
      },
      hideNotifications() {
          this.showNotifications = false;
      },
      handleIconHover(hovered) {
          this.isIconHovered = hovered;
      },
      handleNotifHover(key){
          this.isNotifHovered = key;
      },
      handleClearHover(key){
          this.clearHover = key;
      },
      handleReadHover(key){
          this.readHover = key;
      },
  },
  computed: {
      user_id: function() {
        return localStorage.getItem("user_id");
      },
      user_worker_id: function() {
        return localStorage.getItem("worker_id");
      },
  },
  mounted() {
      this.getUnreadIndicator();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep .card {
    position:absolute !important;
    top: 65px;
    right: 250px;
    width: 500px;
    /* min-height: 500px; */
    border: none;
    border-radius: 15px;
    box-shadow: 1px 1px 10px #16171814;
    z-index: 100 !important;
}
::v-deep .notification-card-head {
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    padding: 5px 15px;
    height: 50px;
    font-size: 14px;
    border-bottom: 1px solid #E6EEF9;
}
::v-deep .notification-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 15px;
}
::v-deep .no-notifications-row {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    background-color: #F3F8FD;
    padding: 20px 15px;
}
::v-deep .notification-card-foot {
    display:flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-800);
    border-top: 1px solid #E6EEF9;
}
::v-deep .card-body {
    padding: 0 !important;

}
</style>