<template>
  <b-button
    id="feedback-button"
    pill
    variant="primary"
    :disabled="this.feedbackFormUrl == null"
    @click="openFeedbackForm"
    >Submit feedback</b-button
  >
</template>

<script>
import { make_url, authenticated_request } from '../common'

export default {
  name: "FeedbackButton",
  props: {
    feedbackFormName: {
      type: String,
      required: false,
      default: "tfc-x-avosina-software-feedback",
    },
  },
  data() {
    return {
      feedbackFormUrl: null,
    };
  },
  methods: {
    openFeedbackForm() {
      // Opens url for feedback in new tab
      window.open(this.feedbackFormUrl, "_blank");
    },
  },
  mounted() {
    // Get url for feedback form
    const url = make_url('/lookup/feedback_form');
    const vm = this;
    authenticated_request({
        method: "GET",
        url: url,
        params: {
          form_name: this.feedbackFormName,
        },})
        .then(response => {
          vm.feedbackFormUrl = response.data.url;
        })
        .catch(error => {
          console.log(error);
          vm.feedbackFormUrl = 'https://two.fish'
        });
  }
};
</script>

<style scoped>
#feedback-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 50;
  box-shadow: 2px 2px 2px #ccc;
}
</style>