



















import { logout, listUserRoleNames } from '../common.js'
import NotificationDropDown from  './NotificationDropDown.vue'

export default {
  name: 'CurrentUser',
  components: { NotificationDropDown },
  data() {
      return {
          user: {
              username: "loading...",
              given_name: "loading...",
              family_name: "loading...",
              nick_name: "loading...",
              name: "loading...",
              role: "loading...",
          }
      }
  },
  methods: {
      logout: function(){
          logout();
      }
  },
  computed: {
      jwt(): string | null {
          return localStorage.getItem("jwt");
      },
      user_role_names(): string {
          var roles = listUserRoleNames()
          if(!roles || roles.length == 0){
              return ("No role assigned")
          }
          
          return roles.join(', ')
      },
      user_full_name(): string | null {
          return localStorage.getItem("user_full_name");
      }
  },
}
