<template>
<div id="wrapper">
    <a href="#content" class="skip">Skip to content</a>
    <div id="header">
        <div id="logo" class="ml-4 mb-n4 pb-2 pt-3">
            <img :src="require('@/assets/logo.png')" />
        </div>
        <current-user />
    </div>
    <sidebar id="portal-sidebar" />
    <div id="content" class="component-view mt-1">
        <show-general-errors></show-general-errors>
        <transition name="fade">
            <keep-alive :include="cachedComponentNames" max=5>
                <router-view></router-view>
            </keep-alive>
        </transition>
    </div>
    
    <div id="footer"></div>
    <feedback-button/>
</div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue"
import CurrentUser from "@/components/CurrentUser.vue"
import ShowGeneralErrors from "@/components/ShowGeneralErrors.vue"
import FeedbackButton from "@/components/FeedbackButton.vue"

export default {
  name: 'Claims',
  components: {
      Sidebar,
      CurrentUser,
      ShowGeneralErrors,
      FeedbackButton,
  },
  data(){
      return {
          cachedComponentNames: [
              'Claims',
              'IssuesClaims',
              'Practices',
              'Payers',
          ]
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.component-view {
    min-height:100vh;
    background-color: white;
    padding: 30px;
    border-top-left-radius: 15px;
}
#portal-sidebar {
    position:sticky;
    top:0;
    height:100vh;
    z-index:50;
}
</style>